import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BMXSelectField from "../../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";
import { Attachments } from "../../../../../../Utils/GlobalVariables/Statuses/GlobalStatuses";
import { GlobalAuthFetch } from "../../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { IconButton, Tooltip } from "@mui/material";
import { Icon } from "@mui/material";

const RegistryFormStepOne = (props) => {
  const { statuses, folders, setStatuses, setFolders, values, initialValues } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAddStatusModal = (props) => dispatch(show("statusModal", props));
  const openAddFolderModal = (props) => dispatch(show("folderModal", props));
  const [currentManagers, setCurrentManagers] = useState([]);
  const attachments = Attachments(t);

  useEffect(() => {
    const fetchData = async () => {
      const { 0: resManagers } = await Promise.all([
        GlobalAuthFetch({ path: "/worker/all" }),
      ]);

      if (resManagers?.payload?.status === 200) {
        const { data } = resManagers.payload;

        setCurrentManagers(
          data.map((e) => ({
            value: e.uuid,
            name: `${e.firstName || ""} ${e.lastName || ""}`,
          }))
        );
      }
    };
    fetchData();
  }, [dispatch]);

  const openMUIWebPage = () => {
    const url = "https://mui.com/material-ui/material-icons/"; // Replace with your desired URL
    const target = "_blank"; // Open in a new tab or window

    window.open(url, target);
  };

  useEffect(() => {
    setFolders(folders.sort((a, b) => a?.name?.localeCompare(b?.name)));
    // eslint-disable-next-line
  }, [folders]);

  return (
    <div>
      <BMXSeparator title={t("global:details")} />
      <Row>
        <Col xs={5}>
          <Field
            component={BMXTextField}
            label={`${t("global:registry.name")}*`}
            name="name"
          />
        </Col>
        <Col xs={5}>
          <Field
            component={BMXTextField}
            label={`${t("global:registry.index")}*`}
            name="index"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:registry.url")}*`}
            name="url"
          />
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
          }}
          xs={4}
        >
          <Field
            component={BMXTextField}
            label={`${t("global:registry.icon")}*`}
            name="icon"
          />
          <Tooltip title={t("global:muiWebPageRedirect")} placement="top">
            <IconButton
              style={{ color: "#445d75" }}
              onClick={openMUIWebPage}
              size="small"
            >
              <Icon>art_track</Icon>
            </IconButton>
          </Tooltip>
        </Col>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={[
              { name: t("global:yes"), value: true },
              { name: t("global:no"), value: false },
            ]}
            label={t("global:registry.isActive")}
            name="isActive"
          />
        </Col>
      </Row>
      <BMXSeparator title={t("global:registry.settings")} />
      <Row>
        <Col xs={6}>
          <Field
            MenuProps={{ disableEnforceFocus: true }}
            component={BMXSelectField}
            renderValue={(e) => `${e.name} (${t("global:default")})`}
            renderValueSurfix={`(${t("global:default")})`}
            values={statuses
              .filter((e) => !e.isDeleted)
              .map((el, index) => ({
                ...el,
                icons: [
                  {
                    onClick: (e) => {
                      openAddStatusModal({
                        ...e,
                        header: t("global:registry.status.update"),
                        initialValues: { ...el },
                        update: true,
                        setStatuses: setStatuses,
                        statuses: statuses,
                        selectedElement: { ...el, index: index },
                      });
                    },
                    icon: "create",
                  },
                  {
                    onClick: (e, el) => {
                      values.defaultStatus = "";
                      el.isDeleted = true;
                      setStatuses([
                        ...statuses.filter((elm) => elm.name !== el.name),
                        el,
                      ]);
                    },
                    icon: "delete",
                  },
                ],
              }))}
            afterIcon="add"
            afterIconClick={(e) =>
              openAddStatusModal({
                ...e,
                header: t("global:registry.status.add"),
                setStatuses: setStatuses,
                statuses: statuses,
              })
            }
            label={`${t("global:registry.status.statuses")}*`}
            name="defaultStatus"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            afterIcon="add"
            MenuProps={{ disableEnforceFocus: true }}
            values={folders.map((el, index) => ({
              ...el,
              icons: [
                {
                  onClick: (e) => {
                    openAddFolderModal({
                      ...e,
                      header: t("global:registry.folder.update"),
                      initialValues: { ...el },
                      update: true,
                      setFolders: setFolders,
                      folders: folders.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      ),
                      selectedElement: { ...el, index: index },
                    });
                  },
                  icon: "create",
                },
                {
                  onClick: (e, el) => {
                    values.availableFolders = "";
                    el.isDeleted = true;
                    setFolders([
                      ...folders.filter((elm) => elm.name !== el.name),
                    ]);
                  },
                  icon: "delete",
                },
              ],
            }))}
            afterIconClick={(e) =>
              openAddFolderModal({
                ...e,
                header: t("global:registry.folder.add"),
                setFolders: setFolders,
                folders: folders,
              })
            }
            label={`${t("global:registry.folder.label")} + ${
              folders.filter((el) => !el.isDeleted).length || 0
            }`}
            name="availableFolders"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={[
              attachments.active,
              attachments.draft,
              attachments.secret,
              attachments.archive,
            ]}
            label={`${t("global:registry.status.defaultStatusFiles")}*`}
            name="defaultStatusFiles"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={currentManagers}
            initialValues={{
              availableManagers: initialValues?.availableManagers?.map((e) => ({
                value: e.uuid,
                name: `${e.firstName || ""} ${e.lastName || ""}`,
              })),
            }}
            selectedValues={values}
            multiple
            displayEmpty
            label={`${t("global:registry.status.avaliableManager")}*`}
            name="availableManagers"
            renderValue={(el) => {
              return el.map((e) => e.name).join(", ");
            }}
          />
        </Col>
        <Col xs={6}>
          <Field
            values={currentManagers}
            name="defaultManager"
            label={t("global:registry.manager")}
            component={BMXSelectField}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RegistryFormStepOne;
